import {
  Button,
  Text,
  HStack,
  VStack,
  Center,
  Box,
  useDisclosure,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import Layout from "@/components/Layout";
import Head from "next/head";
import { useEffect, useState } from "react";
import useMainStore from "@/stores/mainStore";
import { isOneOf } from "@/func/helpers";
import { NonOptionalKeys, RequestState } from "@/types";
import useAiData from "@/hooks/useAiData";
import { PromptParams as MetaParams, Result as MetaResult } from "@/types/meta";
import useConfigStore from "@/stores/configStore";
import TextfieldHeaderBar from "@/components/TextfieldHeaderBar";
import { extractEditableData } from "@/lib";

const MAX_CHARS_INPUT = 70;
const MAX_CHARS_INFO = 8000;

export default function StartPage() {
  const [additionalInfo, setAdditionalInfo] = useState<string>("");

  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const {
    productDescription,
    setProductDescription,
    metaMessage,
    setMetaMessageField,
    revertMetaMessage,
  } = useMainStore();

  const { useOffline } = useConfigStore();

  const { onSubmit, result, state } = useAiData<
    MetaResult,
    NonOptionalKeys<MetaParams> | MetaParams
  >(
    "meta-message",
    {
      mode: useOffline,
      product: productDescription,
      additionalInfo,
      useBulletPoints: true,
    },
    productDescription
  );

  useEffect(() => {
    if (state === "success" && result) {
      setMetaMessageField(result, "base");
    }
  }, [result, state, setMetaMessageField]);

  const onCopy = () => {
    if (!metaMessage) return;
    const str = extractEditableData(metaMessage);
    navigator.clipboard.writeText(str);
  };

  return (
    <Layout
      pageNext={{ href: "/devices", name: "generate messages" }}
      nextDisabled={!metaMessage || metaMessage.base.length === 0}
      doPulse={state === "loading"}
      footerChildren={
        <VStack
          mx="auto"
          w="container.sm"
          align="right"
          zIndex="overlay"
          bg="white"
          rounded="16"
          overflow="hidden"
          maxW="full"
          p={2}
        >
          <Textarea
            color="black"
            p={2}
            w="full"
            fontSize="sm"
            border="none"
            value={productDescription}
            onChange={(e) => setProductDescription(e.target.value)}
            placeholder="What should be promoted? e.g. '20% off on Rear-axle Steering for Easter'"
            h={productDescription.length > MAX_CHARS_INPUT ? "32" : "12"}
            style={{ transition: "all 100ms ease-in-out" }}
            minH="min-content"
            resize="none"
          />
          <HStack align="flex-end" w="full">
            <Box flex="1"></Box>
            <Button
              variant="link"
              colorScheme="brand"
              onClick={modalOnOpen}
              size="sm"
              h="full"
              fontWeight="normal"
            >
              + add source
            </Button>
            <Button
              rounded="full"
              colorScheme="brand"
              size="sm"
              onClick={onSubmit}
              disabled={!productDescription.length}
              _disabled={{ background: "gray.400" }}
            >
              Generate
            </Button>
          </HStack>
        </VStack>
      }
    >
      <Head>
        <title>synapse : start</title>
      </Head>

      <Center flex="1" w="full" gridArea="content">
        {isOneOf<RequestState>(state, ["loading"]) && (
          <VStack color="white">
            <Text>generating brief…</Text>
          </VStack>
        )}
        {isOneOf<RequestState>(state, ["none", "success"]) &&
          metaMessage?.base.length && (
            <VStack
              align="baseline"
              color="white"
              h="full"
              w="full"
              maxW="container.md"
            >
              <TextfieldHeaderBar
                title="Generated brief"
                onCopy={onCopy}
                onRevert={revertMetaMessage}
                onRegenerate={onSubmit}
                buttonsDisabled={state !== "success"}
              />
              {/* <Heading size="sm">Generated Brief</Heading> */}
              <Textarea
                bg="whiteAlpha.300"
                rounded="lg"
                w="full"
                value={metaMessage.user}
                onChange={(e) => {
                  setMetaMessageField(e.target.value, "user");
                }}
                p={8}
                h="full"
                resize="none"
                border="none"
                lineHeight="8"
                fontSize="sm"
                backdropFilter="auto"
                backdropBlur="lg"
                _focus={{ outline: "none", boxShadow: "none" }}
              />
              <HStack direction="row-reverse" w="full" spacing={4}></HStack>
            </VStack>
          )}
      </Center>
      <Modal isOpen={modalIsOpen} onClose={modalOnClose} size="xl">
        <ModalOverlay backdropFilter="auto" backdropBlur="8px" />
        <ModalContent>
          <ModalHeader>Additional Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} w="full" align="basline">
              <Textarea
                p={4}
                w="full"
                fontSize="sm"
                border="none"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                placeholder="Enter additional information here like the text from a product website"
                h="96"
                resize="none"
                bg="gray.200"
              />

              <Text
                color={
                  additionalInfo.length > MAX_CHARS_INFO ? "red" : undefined
                }
                fontSize="xs"
                textAlign="right"
              >
                {additionalInfo.length} / {MAX_CHARS_INFO} characters
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4}>
              <Button
                colorScheme="brand"
                rounded="full"
                variant="link"
                onClick={() => setAdditionalInfo("")}
              >
                Clear
              </Button>
              <Button colorScheme="brand" rounded="full" onClick={modalOnClose}>
                Confirm
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Layout>
  );
}
